<script>
	import Vue from 'vue';
	import BaseVue from '@frontend/Base.vue';
	import Gen from '../helper/Gen';

	export default {
		extends: BaseVue,
		data() {
			return {
			}
		},
		async mounted() {
			await this.ready();
            this.$set(this.$root, 'page', this);
		},
	};
</script>
<template>
	<div>
		<section id="content" style="overflow: visible">
		    <div class="content-wrap p_0">
		        <div class="clear"></div>
		        <div class="section  notopmargin nobottommargin">
		            <div class="container clearfix">
		                <div class="row justify-content-center">
		                    <div class="col-md-10 center">
		                        <div class="heading_section">
		                            <h2>Term & Condition</h2>
		                        </div>
		                    </div>
		                </div>
		                <div class="row justify-content-center">
		                    <div class="col-md-11 col-lg-12">
		                        <div class="info_produk_detail">
		                            <h2>Kebijakan Privasi Astra Ventura</h2>
		                            <p>Kebijakan Privasi mengatur cara www.visiviyatalestari.com mengumpulkan, menggunakan, memelihara, dan mengungkapkan informasi yang dikumpulkan dari pengguna (masing-masing, "Pengguna") dari situs web www.visiviyatalestari.com ("Situs"). Kebijakan privasi ini berlaku untuk Situs dan semua layanan yang ditawarkan oleh http://www.visiviyatalestari.com/</p>
		                        </div>
		                        <div class="info_produk_detail">
		                            <h2>Informasi Identifikasi Pribadi</h2>
		                            <p>Kami dapat mengumpulkan informasi identifikasi pribadi dari Pengguna dalam berbagai cara, termasuk, tetapi tidak terbatas pada, ketika Pengguna mengunjungi situs kami, mendaftar di situs, memesan, dan sehubungan dengan kegiatan, layanan, fitur atau sumber daya lain yang kami buat. tersedia di Situs kami. Pengguna dapat mengunjungi Situs kami secara anonim. Kami akan mengumpulkan informasi identifikasi pribadi dari Pengguna hanya jika mereka secara sukarela menyerahkan informasi tersebut kepada kami. Pengguna selalu dapat menolak untuk memberikan informasi identifikasi pribadi, kecuali bahwa hal itu dapat mencegah mereka dari terlibat dalam kegiatan terkait Situs tertentu.</p>
		                        </div>
		                        <div class="info_produk_detail">
		                            <h2>Informasi Identifikasi Non-Pribadi</h2>
		                            <p>Kami dapat mengumpulkan informasi identifikasi non-pribadi tentang Pengguna setiap kali mereka berinteraksi dengan Situs kami. Informasi identifikasi non-pribadi dapat mencakup nama browser, jenis komputer dan informasi teknis tentang Pengguna yang terhubung ke Situs kami, seperti sistem operasi dan penyedia layanan Internet yang digunakan dan informasi serupa lainnya.</p>
		                        </div>
		                        <div class="info_produk_detail">
		                            <h2>Web Browser Cookies</h2>
		                            <p>Kami dapat mengumpulkan informasi identifikasi non-pribadi tentang Pengguna setiap kali mereka berinteraksi dengan Situs kami. Informasi identifikasi non-pribadi dapat mencakup nama browser, jenis komputer dan informasi teknis tentang Pengguna yang terhubung ke Situs kami, seperti sistem operasi dan penyedia layanan Internet yang digunakan dan informasi serupa lainnya.</p>
		                        </div>
		                        <div class="info_produk_detail">
		                            <h2>Bagaimana Kami Menggunakan Informasi yang dikumpulkan?</h2>
		                            <p>http://www.visiviyatalestari.com/ dapat mengumpulkan dan menggunakan informasi pribadi Pengguna untuk tujuan berikut:</p>
		                            <ul>
		                                <li>Untuk meningkatkan layanan pelanggan. Informasi yang Anda berikan membantu kami menanggapi permintaan layanan pelanggan Anda dan kebutuhan dukungan lebih efisien.</li>
		                                <li>Untuk mempersonalisasi pengalaman pengguna. Kami dapat menggunakan informasi secara agregat untuk memahami bagaimana Pengguna kami sebagai grup menggunakan layanan dan sumber daya yang disediakan di Situs kami.</li>
		                                <li>Untuk meningkatkan Situs kami. Kami dapat menggunakan masukan yang Anda berikan untuk meningkatkan produk dan layanan kami.</li>
		                                <li>Untuk membagikan informasi Anda dengan pihak ketiga. Kami dapat berbagi informasi dengan pihak ketiga untuk tujuan pemasaran atau lainnya.</li>
		                                <li>Untuk menjalankan promosi, kontes, survei, atau fitur Situs lainnya</li>
		                                <li>Untuk mengirim informasi kepada Pengguna, mereka setuju untuk menerima topik yang menurut kami menarik bagi mereka.</li>
		                            </ul>
		                        </div>
		                        <div class="info_produk_detail">
		                            <h2>Bagaimana Kami Melindungi Informasi Anda</h2>
		                            <p>Kami mengadopsi praktik pengumpulan, penyimpanan, dan pemrosesan data yang sesuai serta langkah-langkah keamanan untuk melindungi dari akses, perubahan, pengungkapan, atau penghancuran informasi pribadi Anda dan data yang disimpan di Situs kami.</p>
		                        </div>
		                        <div class="info_produk_detail">
		                            <h2>Perubahan pada Kebijakan Privasi ini</h2>
		                            <p>www.visiviyatalestari.com memiliki keleluasaan untuk memperbaharui kebijakan privasi ini kapan saja. Ketika kami melakukannya, kami akan merevisi tanggal yang diperbarui di bagian bawah halaman ini. Kami mendorong Pengguna untuk sering memeriksa halaman ini jika ada perubahan agar tetap mendapat informasi tentang bagaimana kami membantu melindungi informasi pribadi yang kami kumpulkan. Anda mengakui dan setuju bahwa Anda bertanggung jawab untuk meninjau kebijakan privasi ini secara berkala dan mengetahui adanya modifikasi.</p>
		                        </div>
		                        <div class="info_produk_detail">
		                            <h2>Penerimaan Anda terhadap ketentuan ini</h2>
		                            <p>Dengan menggunakan Situs ini, menandakan penerimaan Anda terhadap kebijakan ini. Jika Anda tidak menyetujui kebijakan ini, jangan gunakan Situs kami. Anda terus menggunakan Situs setelah posting perubahan pada kebijakan ini akan dianggap Anda menerima perubahan tersebut.
		                            </p>
		                        </div>
		                    </div>
		                </div>
		            </div>
		        </div>
		        <div class="line line-sm my-0 clearfix"></div>
		        <div class="clear"></div>
		    </div>
		</section>
	</div>
</template>